<template>
    <div class="page-warp container-page">
        <div class="row message-title">{{noticeTitle}}</div>
        <div class="row message-date mt-10">发布时间: {{msgDate}}</div>
        <div class="row message-content mt-20">
            <div class=" editor ql-container">
                <div class="ql-editor" v-html="noticeContent"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { findById } from "@/api/message"
import "../../assets/css/quill.core.css";
import "../../assets/css/quill.snow.css";
import "../../assets/css/quill.bubble.css";
export default {
    name:'MessageDetail',
    data() {
        return {
            id:'',
            noticeTitle:'',
            msgDate:'',
            noticeContent:''
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.findAll();
    },
    methods: {
        findAll() {
            if(this.id){
                findById(this.id).then(res=>{
                    this.msgDate = res.data.createTime;
                    this.noticeTitle = res.data.noticeTitle;
                    this.noticeContent = res.data ? res.data.noticeContent : '';
                });
            }
        },
    },
};
</script>
<style lang="less" scope>
.message-title {
    padding-top:12px;
    font-size: 18px;line-height: 26px; font-weight: 600;
}
.message-date {
    font-size:11px; color: #080808; opacity: 0.4; line-height: 18px;
}
</style>